.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-layout {
  height: 100%;
  width: 100%;
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {

  background: #141414;
}

.layout {
  height: calc(100vh - 72px),
}

.ant-table-thead>tr>th {
  font-weight: 600;
}

// //dots样式的修改
// .slick-dots li button {
//   width: 10px !important;
//   height: 10px !important;
//   background: rgb(255, 255, 255) !important; // 改变dots的颜色
//   border-radius: 100% !important;
// }

// .slick-dots li {
//   width: 23px !important;
// }

// .slick-dots li.slick-active {
//   width: 15px !important;
// }

// .ant-carousel .slick-dots-bottom {
//   bottom: -12% !important;
// }

// //走马灯div宽高百分比
// .ant-carousel {
//   position: relative;
//   height: 100%;
//   width: 100%;
// }

// .ant-carousel .slick-slider {
//   position: relative;
//   height: 100%;
//   width: 100%;
// }

// .slick-list {
//   position: relative;
//   height: 100% !important;
//   width: 100%;
// }

// .slick-track {
//   position: relative;
//   color: #fff;
//   width: 100% !important;
//   height: 100%;
// }

// .slick-slide {
//   position: relative;
//   color: #fff;
//   width: 100%;
//   height: 100%;

//   div {
//     position: relative;
//     width: 100%;
//     height: 100%;
//   }
// }