.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #003f88
}

.time {
  font-size: 16px;
  font-weight: bold;
}

.date {
  font-size: 16px;
}

.weekday {
  font-size: 16px;
}