@import '../../common.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px;

  .left {
    position: relative;
    // width: toVw(600);
    flex: 1;
    height: 100%;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .center {
    position: relative;
    // flex: 1;
    width: toVw(700);
    height: 100%;


    .center_top {
      position: relative;
      width: 100%;
      height: toVh(600);
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

      .chart {
        height: toVh(500);
      }

      .chart_data {
        height: toVh(100);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;

        .chart_data_item {

          font-family: SourceHanSansSC-regular;

          .key,
          .value {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .key {
            font-size: toVh(24);
            color: rgba(142, 142, 142, 100);
            font-weight: 500;
          }

          .value {
            height: toVh(40);
            font-size: toVh(40);
            color: rgba(94, 172, 255, 100);
          }
        }
      }
    }

    .center_bottom {
      position: relative;
      height: calc(100% - toVh(612));
      width: 100%;
      margin-top: toVh(12);
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 0 8px;
      overflow: hidden;

      .img_container {
        padding: 12px 12px 4px 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          // width: 100%;
          border-radius: 12px;
          height: calc(100vh - toVh(636) - 140px);
          object-fit: contain;
        }
      }

      .img_info {
        position: absolute;
        bottom: 0;
        margin-left: -8px;
        width: toVw(700);
        height: toVh(100);
        transform: translateY(35%);
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        color: rgba(142, 142, 142, 83);
        line-height: toVh(60);
        font-size: toVh(24);
        z-index: 1;
      }
    }
  }

  .right {
    position: relative;
    flex: 1;
    height: 100%;

    .right_top {
      position: relative;
      width: 100%;
      height: toVh(600);
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      padding-bottom: 12px;
    }

    .right_bottom {
      position: relative;
      height: calc(100% - toVh(612));
      width: 100%;
      margin-top: toVh(12);
      border-radius: 12px;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 0 12px 16px 12px
    }
  }


  .top_chart {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    .left {
      position: relative;
      width: 20%;
      height: 100%;
    }

    .right {
      position: relative;
      width: calc(80% - 16px);
      height: 100%
    }

    .section {
      position: relative;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      width: 100%;
      height: 100%;

      .header {
        padding: 6px 12px;

        color: #096DD9;


        &>label {
          margin-left: 8px;

          letter-spacing: 0.1em;

          font-size: 18px;
        }
      }

      .line {
        height: 2px;

        background: #f6f9fc;
      }

      .chart {
        height: calc(100% - 71px);
      }
    }

  }



  .swiper {
    position: relative;
    padding: 0 16px;
    width: 100%;
    height: calc(100% - 320px);

    .section {
      position: relative;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      width: 100%;
      height: 100%;

      .header {
        padding: 6px 12px;

        color: #096DD9;


        &>label {
          margin-left: 8px;

          letter-spacing: 0.1em;

          font-size: 18px;
        }
      }

      .line {
        height: 2px;

        background: #f6f9fc;
      }

      .chart {
        height: calc(100% - 71px);
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}