.mySwiper {
  &>div {
    margin-left: 24%;
  }
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.box {
  height: 100%;
  padding-top: 12px;
}