.icon {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 50px;
  height: 42px;
  border-radius: 0px 10px 10px 0px;
  background-color: rgba(255, 255, 255, 100);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 100);
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: .3s;

  &>i {
    color: #5EACFF;
    font-size: 24px;
  }

  &.hiden {
    left: -40px;
  }
}