@import '../../common.scss';

.container {
  width: 100%;
  height: 100vh;
  background-color: #f0f2f5;

  .img {
    position: absolute;
    top: 50%;
    left: 28px;
    transform: translateY(-50%);
    height: toVh(800);
    border-radius: 12px;
  }

  .form {
    position: absolute;
    top: 50%;
    right: 88px;
    transform: translateY(-50%);
    height: toVh(600);
    width: 400px;
    border-radius: 12px;
    background: #fff;

    .password_form {
      padding: 47px 54px;

      h1 {
        height: 30px;
        margin-top: 0;
        margin-bottom: 32px;
        letter-spacing: 0.2em;
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
      }

      input {
        width: 100%;
        height: 42px;
        padding: 0 20px;
        transition: all 0.3s;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        outline: none;
        background: #ffffff;
        font-size: 16px;
      }

      input::placeholder {
        color: #a0aec0;
      }

      input.account_input {
        margin-bottom: toVh(60);
      }

      input.password_input {
        margin-bottom: toVh(60);
      }

      input:focus {
        border-color: #358cff;
      }

      button {
        width: 100%;
        height: 40px;

        cursor: pointer;

        color: #fff;
        border: none;
        border-radius: 8px;
        outline: none;
        background: #358cff;

        font-size: 16px;
      }
    }
  }
}