.section {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  height: 100%;

  .header {
    padding: 6px 12px;

    color: #096DD9;


    &>label {
      margin-left: 8px;

      letter-spacing: 0.1em;

      font-size: 18px;
    }
  }

  .line {
    height: 2px;

    background: #f6f9fc;
  }

  .main {
    height: calc(100% - 43px);
    position: relative;
  }
}