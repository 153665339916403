.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  padding: 16px;

  .tabs {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 12px;

    .back {
      position: absolute;
      right: 12px;
      top: 6px;
    }
  }

}