.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  padding: 16px;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 12px;

    .form {
      position: relative;
      width: 100%;
      padding: 12px;

      .search_btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .reset {
        margin-right: 12px;
      }

      .line {
        height: 2px;

        background: #f6f9fc;
      }

      .option {
        display: flex;
        align-items: center;
        justify-content: end;

        .option_detail {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transform: translateY(3px);

          &>span {
            margin-left: 6px;
          }
        }
      }

      .opt {
        position: absolute;
        right: 12px;
        bottom: 6px;
        cursor: pointer;
      }
    }


    .table {
      height: calc(100vh - 320px);
      overflow: auto;
    }

    .pagination {
      position: absolute;
      bottom: 12px;
      right: 12px;
    }
  }
}