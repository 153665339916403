.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  padding: 16px;

  .basic_info {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;

    .back {
      position: absolute;
      right: 12px;
      top: 6px;
    }

    .up_btn {
      position: absolute;
      right: 176px;
      top: 6px;
    }

    .down_btn {
      position: absolute;
      right: 90px;
      top: 6px;
    }
  }

  .step {
    margin-top: 6px;
  }

  .footer {
    position: relative;
    height: calc(100% - 340px);
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mp3 {
      width: 500px;
      height: 100%;

      .mp3_container {
        padding: 24px;

        .book_name {
          font-size: 16px;
        }

        .audio {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 32px;
        }
      }
    }

    .up_img {
      flex: 1;
      height: 100%;
      position: relative;
      margin-left: 8px;

      .img_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1%;
        height: 100%;

        .img {
          width: 32%;
          height: 32%;
          object-fit: contain;
        }

        .img_one {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .img_t2t {
          width: 47%;
          height: 47%;
          object-fit: contain;
        }
      }

      // .img_wrapper .img:nth-last-child(1):first-child {
      //   width: 100%;
      //   height: 100%;
      // }

      // .img_wrapper .img:nth-last-child(4):first-child~ :nth-child(2n) {
      //   margin-right: 32%;
      // }

      // .img_wrapper .img:nth-last-child(4):first-child,
      // .img_wrapper .img:nth-last-child(4):first-child~.img {
      //   width: 50%;
      //   margin-right: 0;
      // }
    }
  }
}