@import '../../../common.scss';

.phb_container {
  width: 100%;
  position: relative;
  overflow: auto;
  height: calc(100% - 12px);
  padding: 0 12px;
  margin-top: 12px;

  .row {
    height: toVh(65);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    border-radius: 10px;
    background-color: #f0f8ff;
    font-size: toVh(24);
    margin-top: toVh(16);

    .key {
      color: rgba(9, 109, 217, 100);
      width: toVh(160);
      display: inline-block;
    }

    .value {
      width: calc(100% - toVh(172));
      display: inline-block;
      color: rgba(142, 142, 142, 100);
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
    }
  }
}