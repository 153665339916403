.section {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  height: 100%;

  .header {
    padding: 12px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #003F88;
    font-size: 24px;
    font-weight: 600;
    font-family: SourceHanSansSC-medium;
  }


  .main {
    height: calc(100% - 50px);
    position: relative;
  }
}