.container {
  height: 72px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    margin-left: 20px;
    height: 54px;
  }

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #003f88;
    font-size: 28px;
    letter-spacing: 4px;
    font-weight: 600;
    font-family: SourceHanSansSC-bold;
  }


  .out {
    margin-right: 18px;
    display: flex;
    align-items: end;
    flex-direction: column;
    font-family: SourceHanSansSC-bold;

    .user {
      color: #003f88;
      font-size: 16px;
      cursor: pointer;
      transform: translateY(4px);
    }
  }
}